$color: #9b9bbe;
$color-gray: #73738d;
$color-blue: #2c50ee;
$color-blue-2: #012EE8;
$color-blue-secondary: #1943ef;
$color-blue-dark: #333e63;
$color-bgr: #f8f9fd;
$color-bgr-disabled: #e1e1ea;
$color-active: #00D081;
$color-input: #f8f9fd;
$color-error: #EB2A50;
$color-red: #F94341;
$color-list: #3e4958;
$color-list-border: #F5F5FB;
$color-list-border-blank: #cdcddf;
$color-checkbox: #A8A8C6;
$color-green: #00D081;
$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-premium: #F2A600;

$status-new: #00D081;
$status-in_way_for_load: #BB6BD9;
$status-in_way_for_unload: #24CAE7;
$status-ready_for_load: #FF9900;
$status-ready_for_unload: #FF9900;
$status-accepted: #012EE8;
$status-loaded: #B7D24B;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

.User-form {
	padding: 20px 64px;
	padding-bottom: 120px;

	@include media-breakpoint-down(xl) {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 0;

		&:first-child {
			padding-top: 20px;
		}
	}

	@include media-breakpoint-down(xs) {
		padding-bottom: 20px;
	}

	.form-field__field {
		height: 40px;
		padding-left: 12px;
		padding-right: 12px;

		&[type="password"] {
			padding-left: 40px;
		}
	}

	.button {
		position: absolute;
		bottom: 56px;
		left: 64px;
		width: calc(100% - 128px);

		@include media-breakpoint-down(xl) {
			position: relative;
			bottom: auto;
			left: auto;
			margin: 30px auto 0;
		}
	}
}
