:root {
	--color: #{$color};
	--color-gray: #{$color-gray};
	--color-blue: #{$color-blue};
	--color-blue-secondary: #{$color-blue-secondary};
	--color-blue-dark: #{$color-blue-dark};
	--color-bgr: #{$color-bgr};
	--color-bgr-disabled: #{$color-bgr-disabled};
	--color-active: #{$color-active};
	--color-input: #{$color-input};
	--color-error: #{$color-error};
	--color-red: #{$color-red};
	--color-list: #{$color-list};
	--color-list-border: #{$color-list-border};
	--color-list-border-blank: #{$color-list-border-blank};
	--color-checkbox: #{$color-checkbox};
	--color-green: #{$color-green};

	--font-primary: #{$font-primary};


	--statuses-new: #{$statuses-new};

	--border-color: #{$border-color};
}

.color-green {
	color: $color-green;
}

.color-blue {
	color: $color-blue;
}
