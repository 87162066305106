@import './src/resources';

.PopupAlerts {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: .8;
}

.Content {
	width: 432px;
	max-width: 100%;
	background: #FFFFFF;
	box-shadow: -10px -10px 25px rgba(112, 124, 151, 0.05), 5px 10px 30px rgba(155, 155, 190, 0.15);
	border-radius: 12px;
	overflow: hidden;
	position: relative;
}

.Title {
	font-weight: 700;
	font-size: 18px;
	line-height: 136%;
	color: #fff;
	background: $color-blue-2;
	padding: 5px 22px;
	display: flex;
	align-items: center;
	min-height: 60px;
}

.Message {
	padding: 18px 22px;
	font-weight: 400;
	font-size: 14px;
	line-height: 136%;
	color: #0D1C2E;
	min-height: 72px;
}

.Buttons {
	padding: 0 22px 20px;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	grid-gap: 16px;
}
